import { makeAutoObservable } from 'mobx';
import survey from '../data/survey.json';
import { SurveyItem } from '../types/Survey';

export class SurveyStore {
  surveyItems: SurveyItem[] = survey as SurveyItem[];
  activeSurveyIndex: number = 0;
  answers: { [key: string]: string } = {};
  isSuccess: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  prefillAnswers = () => {
    this.surveyItems.forEach(item => {
      if (item.type === 'fields') {
        item.inputs?.forEach(input => {
          this.answers[input.label] = '';
        });
      }
      if (item.type === 'select') {
        this.answers[item.title] = item.selectItems && item.selectItems[0] ? item.selectItems[0].value : '';
      }
    });
  };

  get activeSurveyItem() {
    return this.surveyItems[this.activeSurveyIndex] ?? this.surveyItems[0];
  }

  get isItemValid() {
    if (this.activeSurveyItem.type === 'fields') {
      return this.activeSurveyItem.inputs?.every(input => !!this.answers[input.label]);
    } else {
      return !!this.answers[this.activeSurveyItem.title];
    }
  }

  changeActiveItem = (index: number) => {
    this.activeSurveyIndex = index;
  };

  changeSurveySelect = (key: string, value: string) => {
    this.answers[key] = value;
  };

  changeSurveyInput = (key: string, value?: string) => {
    this.answers[key] = value ?? '';
  };

  onNextButtonClick = () => {
    if (this.surveyItems?.length - 1 > this.activeSurveyIndex) {
      this.activeSurveyIndex = this.activeSurveyIndex + 1;
    } else {
      this.isSuccess = true;
    }
  };

  onBackButtonClick = () => {
    this.activeSurveyIndex = this.activeSurveyIndex - 1;
  };

  resetSurvey = () => {
    this.activeSurveyIndex = 0;
    this.answers = {};
    this.isSuccess = false;
  };
}
