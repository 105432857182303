import { Link } from "react-router-dom";
import { Footer } from "../../components/Footer/Footer";
import { PlansBlock } from "../../components/MainPagePlans/PlansBlock";
import { MobilePlans } from "../../components/MainPagePlans/MobilePlans";

export const MainPage = () => {
  return (
    <div className="MainPage">
      <div className="MainPage__bottomBackground" />
      <div className="MainPage__content">
        <div className="MainPage__logoBlock">
          <div className="Logo" />
        </div>
        <div className="MainPage__mainBlock">
          <div className="MainPage__mainImage" />
          <div className="MainPage__mainBlock__title">
            The Ultimate Embedded Data Bus
          </div>
          <div className="MainPage__mainBlock__description">
            Powerful, Secure, and Versatile Data Storage and Transfer
          </div>
          <div className="MainPage__mainBlock__buttons">
            <a
              href="https://github.com/octopus-foundation/octopus/tree/main/shared/nerve"
              target="_blank"
              rel="noreferrer"
              className="Button secondary"
            >
              <div className="Icon github" />
              Try free
            </a>
            <Link to="/survey" className="Button primary">
              Request a quote
            </Link>
          </div>
        </div>
        <div className="MainPage__descriptionBlock">
          <div className="MainPage__logo2" />
          <div className="MainPage__description">
            <div>
              is a high-performance embedded data bus, capable of handling
              infinite queues and storing data across multiple mediums.
              <br />
              These mediums include, but are not limited to, MySQL, Amazon S3,
              and Backblaze B2.
            </div>
            <div>
              The software employs advanced techniques for optimal compression
              and encryption, and removes all meta- information, ensuring high
              levels of data security.
            </div>
          </div>
        </div>
        <div className="MainPage__plans__titles__main">Plans</div>
        <PlansBlock />

        <MobilePlans />

        <div className="MainPage__communityPlanBlock">
          <div className="MainPage__communityPlanBlock__left">
            <div className="Icon community-plan" />
            <div className="MainPage__communityPlanBlock__content">
              <div className="MainPage__communityPlanBlock__title">
                Community plan
              </div>
              <div className="MainPage__communityPlanBlock__badge">
                Free forever!
              </div>
            </div>
          </div>
          <a
            href="https://github.com/octopus-foundation/octopus/tree/main/shared/nerve"
            target="_blank"
            rel="noreferrer"
            className="Button secondary"
          >
            <div className="Icon github" />
            Try free
          </a>
        </div>
        <Footer />
      </div>
    </div>
  );
};
