import { observer } from 'mobx-react';
import { useStore } from '../../stores';
import cn from 'classnames';

export const SurveyProgress = observer(() => {
  const store = useStore();
  const { surveyStore } = store;
  const { activeSurveyIndex, surveyItems } = surveyStore;

  return (
    <div className="SurveyProgress">
      {surveyItems.map((item, i) => (
        <div
          className={cn('SurveyProgress__itemContainer', {
            active: activeSurveyIndex === i,
            completed: activeSurveyIndex > i,
          })}
        >
          {i > 0 ? <div className="SurveyProgress__line" /> : null}
          <div className={'SurveyProgress__item'} />
        </div>
      ))}
    </div>
  );
});
