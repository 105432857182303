import { Link } from "react-router-dom";
import plans from "../../data/plans.json";
import { PlanCell } from "./PlansBlock";
import { ReactNode } from "react";

export const MobilePlans = () => {
  return (
    <div className="MainPage__plansMobile">
      <div className="MainPage__plansMobile__title">Plans</div>
      <MobilePlan
        price={
          <>
            <span>$2000</span>
            <span className="MainPage__plans__title__price__small">
              per server
            </span>
          </>
        }
        type="basic"
        label="Basic"
      />
      <MobilePlan price="By request" type="enterprise" label="Enterprise" />
      <MobilePlan price="By request" type="corporation" label="Corporation" />
    </div>
  );
};

const MobilePlan = ({
  type,
  label,
  price,
}: {
  type: "basic" | "enterprise" | "corporation";
  label: string;
  price: string | ReactNode;
}) => {
  return (
    <div className="MainPage__plansMobile__block">
      <div className="MainPage__plansMobile__block__title">
        <div className={`Icon ${type}-plan`} />
        <div>{label}</div>
        <div className="MainPage__plans__title__price">{price}</div>
      </div>
      <div className="MainPage__plansMobile__block__info">
        {plans.map((plan) => (
          <div key={plan.name} className="MainPage__plansMobile__infoItem">
            <div className="MainPage__plansMobile__infoItem__title">
              {plan.name}
            </div>
            <div className="MainPage__plansMobile__infoItem__value">
              <PlanCell isCheckbox={plan.isCheckbox} value={plan[type]} />
            </div>
          </div>
        ))}
      </div>
      <Link to="/survey" className="Button primary">
        Request a quote
      </Link>
    </div>
  );
};
