import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { MainPage } from './pages/MainPage/MainPage';
import { ScrollToTop } from './components/ScrollToTop';
import { SurveyPage } from './pages/SurveyPage/SurveyPage';

function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/survey" element={<SurveyPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
