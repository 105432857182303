export const Footer = () => {
  return (
    <footer className="Footer">
      <div className="Footer__block logo">
        <div className="Logo" />
        <div className="Footer__companyInfo">
          Nerve made by
          <a target="_blank" rel="noreferrer" href="https://d-tech.ge">
            D-Tech LLC
          </a>
        </div>
      </div>
      <div className="Footer__block">
        <div className="Footer__block__title">Legal</div>
        <div className="Footer__block__items">
          <a href="/">Privacy Policy</a>
          <a href="/">Terms Of Use</a>
        </div>
      </div>
      <div className="Footer__block">
        <div className="Footer__block__title">Links</div>
        <div className="Footer__block__items">
          <a href="/">
            Github
            <div className="Icon github-2" />
          </a>
          <a href="/">
            Crunchbase
            <div className="Icon crunchbase" />
          </a>
        </div>
      </div>
      <div className="Footer__block contacts">
        <div className="Footer__block__title">Contact us</div>
        <div className="Footer__block__info">
          <div className="Footer__block__info__item">
            <div className="Footer__block__info__title">Support</div>
            <div className="Footer__block__info__value">support@nerve.com</div>
          </div>
          <div className="Footer__block__info__item">
            <div className="Footer__block__info__title">Sales</div>
            <div className="Footer__block__info__value">sales.dtech@proton.me</div>
          </div>
        </div>
      </div>
    </footer>
  );
};
