import { Link } from "react-router-dom";
import plans from "../../data/plans.json";
import { Checkbox } from "../Checkbox/Checkbox";

export const PlansBlock = () => {
  return (
    <div className="MainPage__plans">
      <div className="MainPage__plans__top">
        <div className="MainPage__plans__titles__withIcons" />
        <div className="MainPage__plans__titles__withIcons">
          <div className="Icon basic-plan" />
          <div className="MainPage__plans__title">Basic</div>
          <div className="MainPage__plans__title__price">
            <span>$2000</span>
            <span className="MainPage__plans__title__price__small">
              per server
            </span>
          </div>
        </div>
        <div className="MainPage__plans__titles__withIcons">
          <div className="Icon enterprise-plan" />
          <div className="MainPage__plans__title">Enterprise</div>
          <div className="MainPage__plans__title__price">By request</div>
        </div>
        <div className="MainPage__plans__titles__withIcons">
          <div className="Icon corporation-plan" />
          <div className="MainPage__plans__title">Corporation</div>
          <div className="MainPage__plans__title__price">By request</div>
        </div>
      </div>
      <div className="MainPage__plans__table">
        {plans.map((plan) => (
          <div key={plan.name} className="MainPage__plans__table__row">
            <div className="MainPage__plans__table__name">{plan.name}</div>
            <PlanCell value={plan.basic} isCheckbox={plan.isCheckbox} />
            <PlanCell value={plan.enterprise} isCheckbox={plan.isCheckbox} />
            <PlanCell value={plan.corporation} isCheckbox={plan.isCheckbox} />
          </div>
        ))}
      </div>
      <div className="MainPage__plans__buttons">
        <div></div>
        <div>
          <Link to="/survey" className="Button primary">
            Request a quote
          </Link>
        </div>
        <div>
          <Link to="/survey" className="Button primary">
            Request a quote
          </Link>
        </div>
        <div>
          <Link to="/survey" className="Button primary">
            Request a quote
          </Link>
        </div>
      </div>
    </div>
  );
};

export const PlanCell = ({
  value,
  isCheckbox,
}: {
  value: string | boolean;
  isCheckbox?: boolean;
}) => {
  return (
    <div>
      {isCheckbox ? (
        <Checkbox isChecked={value as boolean} />
      ) : value === false ? (
        <Checkbox isChecked={false} />
      ) : (
        value
      )}
    </div>
  );
};
