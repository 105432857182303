import { observer } from "mobx-react";
import cn from "classnames";
import { useStore } from "../../stores";
import { SurveyProgress } from "../../components/SurveyProgress/SurveyProgress";
import { Link } from "react-router-dom";
import { SurveyForm } from "../../components/SurveyForm/SurveyForm";
import { useEffect } from "react";

export const SurveyPage = observer(() => {
  const store = useStore();
  const { surveyStore } = store;
  const {
    activeSurveyItem,
    onNextButtonClick,
    onBackButtonClick,
    activeSurveyIndex,
    surveyItems,
    isItemValid,
    resetSurvey,
    isSuccess,
    prefillAnswers,
  } = surveyStore;
  const { icon, title } = activeSurveyItem;

  useEffect(() => {
    prefillAnswers();
    return () => {
      resetSurvey();
    };
  }, [resetSurvey, prefillAnswers]);
  return (
    <div className="SurveyPage">
      {isSuccess ? (
        <div className="SurveyPage__success">
          <div className="Icon success" />
          <div className="SurveyPage__success__title">Thank you!</div>
          <div className="SurveyPage__success__description">
            Appreciate your interest!
            <br />
            We will contact you shortly to discuss the details and schedule a
            meeting for a product demonstration in Zoom.
          </div>
          <Link to="/">
            <button className="Button primary">Back to home</button>
          </Link>
          <div className="Logo SurveyPage__logo" />
        </div>
      ) : (
        <>
          <div className="SurveyPage__left">
            <div className={cn("Icon SurveyPage__surveyIcon", icon)} />
            <SurveyProgress />
            <div className="SurveyPage__title">{title}</div>
            <div className="Logo SurveyPage__logo" />
          </div>
          <div className="SurveyPage__right">
            <Link to="/">
              <div className="SurveyPage__backButton">
                <div className="Icon arrow-left" />
                Back to nerve.net
              </div>
            </Link>
            <SurveyForm />
            <div className="SurveyPage__buttons">
              {activeSurveyIndex > 0 ? (
                <button
                  onClick={onBackButtonClick}
                  className="Button secondary"
                >
                  Back
                </button>
              ) : null}
              <button
                onClick={isItemValid ? onNextButtonClick : undefined}
                className={cn("Button primary", { disabled: !isItemValid })}
              >
                {activeSurveyIndex === surveyItems.length - 1
                  ? "Submit"
                  : "Next"}
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
});
